import consumer from "./consumer"

consumer.subscriptions.create({
  channel: "CountersChannel"
}, {
  received() {
    this.updateCounters()
  },

  updateCounters() {
    fetch("/mensagens/contadores")
      .then(response => response.text())
      .then(html => {
        let target = document.querySelector("#contadores")
        target.innerHTML = html
      })
  },


})
