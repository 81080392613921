import consumer from "./consumer"

consumer.subscriptions.create({
  channel: "MessageChannel"
}, {
  received(data) {
    this.appendLine(data)
    this.updateCounters()
  },

  updateCounters() {
    fetch("/mensagens/contadores")
      .then(response => response.text())
      .then(html => {
        let target = document.querySelector("#contadores")
        target.innerHTML = html
      })
  },

  appendLine(data) {
    const html = this.createLine(data)
    const element = document.querySelector("#pendentes_table > tbody")
    if(element) {
      element.insertAdjacentHTML("beforeend", html)
    }

  },


  createLine(data) {
    let created_at = moment(data["created_at"]).format("DD/MM/YYYY HH:mm");
    return `<tr id="msg_${data["id"]}">
      <td class="mailbox-rejeitada">
          <a href="#" data-msgid="${data["id"]}" data-url="/mensagens/${data["id"]}" data-action="click->table-actions#rejeitada">
            <i class="fas fa-trash-alt" title="Rejeitar"></i>
          </a>
        </td>
        <td class="mailbox-lida">
          <a href="#" data-msgid="${data["id"]}" data-url="/mensagens/${data["id"]}" data-action="click->table-actions#lida">
            <i class="fas fa-check" title="Marcar como Lida"></i>
          </a>
        </td>
      <td class="mailbox-name">${data["remetente"]}</td>
      <td class="mailbox-subject">${data["conteudo"]}
      </td>
      <td class="mailbox-date">${created_at}</td>
    </tr>`
  }
})
