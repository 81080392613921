// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "@fortawesome/fontawesome-free/js/all";
import "admin-lte/dist/js/adminlte.js";
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap");
require('moment');
import moment from "moment";
window.moment = moment;
require("tempusdominus-bootstrap-4");
import "moment/locale/pt-br";
import "tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min.css";
import Swal from "sweetalert2";
window.Swal = Swal;
window.$ = $;


$.fn.datetimepicker.Constructor.Default = $.extend({},
  $.fn.datetimepicker.Constructor.Default, {
    icons: {
      time: "fas fa-clock",
      date: "fas fa-calendar",
      up: "fas fa-arrow-up",
      down: "fas fa-arrow-down",
      previous: "fas fa-chevron-left",
      next: "fas fa-chevron-right",
      today: "fas fa-calendar-check-o",
      clear: "fas fa-trash",
      close: "fas fa-times",
    },
    locale: "pt-br",
    format: "DD/M/YYYY HH::mm"
  }
);

$(document).on("turbolinks:load", function () {
  $(".input-group.date").each(function () {
    var input = $(this).find("input");
    var format = input.data("format") ?
      input.data("format") :
      "DD/M/YYYY HH:mm";
    var date = moment(input.val()).toDate();
    $(this).datetimepicker({
      locale: "pt-br",
      format: format,
      date: date,
    });
  });

});

window.alert_msg = function (alert_type, text) {
  var title = "";
  var icon = "";
  switch (alert_type) {
    case 'notice':
      title = 'Sucesso!'
      icon = "success";
      break;
    case 'alert':
      title = 'Atenção!';
      icon = "warning";
      break
    case 'error':
      title = 'Erro!';
      icon = "error";
      break;
  }
  Swal.fire({
    title: title,
    text: text,
    icon: icon,
    confirmButtonText: "Ok",
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
  });
}

import "controllers"
