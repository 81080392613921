import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  load(event) {
    const element = event.target
    fetch(element.dataset.url)
      .then(response => response.text())
      .then(html => {
        let titulo = document.querySelector("h3.titulo")
        titulo.innerHTML = element.dataset.title
        let target = document.querySelector(".mailbox-messages")
        target.innerHTML = html
      })
    event.preventDefault()
    event.stopImmediatePropagation()
    this.updateCounters()
  }

  connect() {
  }


}
