import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  rejeitada(event) {
    event.preventDefault()
    event.stopImmediatePropagation()
    const element = event.currentTarget
    const csrfToken = document.querySelector("[name='csrf-token']").content;
    fetch(element.dataset.url, {
      method: 'PUT',
      body: JSON.stringify({
        mensagem: {status: 'rejeitada'}
      }),
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
      },
      credentials: 'same-origin'
    }).then(function (response) {
      let tr = document.querySelector("tr#msg_" + element.dataset.msgid)
      tr.parentNode.removeChild(tr)
    })

  }

  lida(event) {
    event.preventDefault()
    event.stopImmediatePropagation()
    const element = event.currentTarget
    const csrfToken = document.querySelector("[name='csrf-token']").content;
    fetch(element.dataset.url, {
      method: 'PUT',
      body: JSON.stringify({
        mensagem: {status: 'lida'}
      }),
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
      },
      credentials: 'same-origin'
    }).then(function (response) {
      let tr = document.querySelector("tr#msg_" + element.dataset.msgid)
      tr.parentNode.removeChild(tr)
    })

  }

  connect() {
  }


}
