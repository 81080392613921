// application_controller.js
import {
  Controller
} from "stimulus";

export default class extends Controller {
  updateCounters() {
    fetch("/mensagens/contadores")
      .then(response => response.text())
      .then(html => {
        let target = document.querySelector("#contadores")
        target.innerHTML = html
      })
  }
}
